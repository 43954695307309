import React, { ReactNode } from 'react';
import { Row, Stack } from '@angellist/adapt';
import { compact } from 'lodash';

import RowLoader from './RowLoader';
import { SimpleTableItem } from '../../../types';

export interface AdaptRowItemProps {
  key?: string | number;
  loading?: boolean;
  label?: string;
  hide?: boolean;
  suffix?: any;
  divider?: boolean;
  compact?: boolean;
  description?: ReactNode;
  tooltip?: {
    heading?: string;
    content?: ReactNode;
  };
}

interface AdaptRowListProps {
  items: AdaptRowItemProps[];
}

const AdaptRowItem = (props: AdaptRowItemProps) => {
  const { label, hide, loading, ...restProps } = props;
  if (hide) {
    return null;
  }

  const rowProps = loading
    ? {
        ...restProps,
        suffix: {
          label: <RowLoader width={100} />,
        },
      }
    : restProps;

  return <Row {...rowProps}>{label}</Row>;
};

AdaptRowItem.defaultProps = {
  loading: false,
  hide: false,
};

export const AdaptRowList = (props: AdaptRowListProps) => {
  const { items } = props;
  const visibleItems = items.filter((item) => !item.hide);
  const len = visibleItems.length;

  return (
    <Stack gap="0">
      {visibleItems.map((item, index) => (
        <AdaptRowItem
          key={item.key || index}
          divider={len !== index + 1}
          {...item}
        />
      ))}
    </Stack>
  );
};

export const processSimpleTableItems = (
  items: SimpleTableItem[],
  loading?: boolean,
) =>
  items.map((item, index: number) => {
    if (loading) {
      return {
        id: index.toString(),
        ...item,
        value: <RowLoader width={100} />,
      };
    }

    return { id: index.toString(), ...item };
  });

export const getSimpleTableLoadingRows = (numLoadingRows: number) =>
  Array(numLoadingRows)
    .fill('')
    .map((_, index: number) => ({
      id: index.toString(),
      label: <RowLoader width={100} />,
      value: <RowLoader width={100} />,
    }));

export const processAdaptRowItems = (items: any[], globolProps: any) => {
  const processedItems = items.map((item, index: number) => {
    const { label, suffixLabel, hide, ...rest } = item;
    if (!hide) {
      return {
        key: index.toString(),
        ...(globolProps || {}),
        label,
        suffix: {
          label: suffixLabel,
        },
        ...rest,
      };
    }
    return null;
  });

  return compact(processedItems);
};

export default AdaptRowItem;
